import styled from "styled-components";

const ReservarCitaWrapper = styled.section`
  position: fixed;
  bottom: 20px;
  right: 0;
  z-index: 10;
`;

export default ReservarCitaWrapper;
