import { NavLink } from "react-router-dom";
import NavbarWrapper from "./NavbarWrapper";

const Navbar = ({ theme = "light" }) => {
  return (
    <NavbarWrapper>
      <div className="container">
        <NavLink to="/">
          <img
            src={
              theme === "light"
                ? "./imgs/white-logo.svg"
                : "./imgs/black-logo.svg"
            }
          />
        </NavLink>
      </div>
    </NavbarWrapper>
  );
};
export default Navbar;
