import styled from "styled-components";

const ModalWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .close-icon{
    position: absolute;
    color: white;
    font-size: 25px;
    right: 5%;
    top: 5%;
    cursor: pointer;
    transition: ease 0.3s color;
  }
  .close-icon:hover{
    color: var(--gray-300);
  }
  .video-container{
    width: 65%;
    aspect-ratio: 10/7;
    background-color: black;
  }
`;

export default ModalWrapper;
