import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import LegalWrapper from "./LegalWrapper";

const Privacy = () => {
  return (
    <LegalWrapper>
      <Navbar theme="dark" />
      <div className="container section">
        <h2>Políticas de privacidad</h2>
        <p>
          GOKOSTUDIO SL, provisto con NIF/CIF: B67448175, dirección Cl Salvador
          Soler I Forment Num.21 P.1 Pta.1 08870 - (Sitges) - Barcelona- España,
          no puede asumir ninguna responsabilidad derivada del uso incorrecto,
          inapropiado o ilícito de la información aparecida en la página de
          Internet de GOKOSTUDIO SL
        </p>
        <p>
          Con los límites establecidos en la ley 31/2002, de 11 de julio, no
          asume ninguna responsabilidad derivada de la falta de veracidad,
          integridad, actualización y precisión de los datos o informaciones que
          se contienen en sus páginas de Internet.
        </p>
        <p>
          Los contenidos e información no vinculan a GOKOSTUDIO SL ni
          constituyen opiniones, consejos o asesoramiento legal de ningún tipo
          pues se trata meramente de un servicio ofrecido con carácter
          informativo y divulgativo.
        </p>
        <p>
          La página de Internet de GOKOSTUDIO SL puede contener enlaces (links)
          a otras páginas de terceras Partes que GOKOSTUDIO SL no puede
          controlar. Por lo tanto, GOKOSTUDIO SL no puede asumir
          responsabilidades por el contenido que pueda aparecer en páginas de
          terceros.
        </p>
        <p>
          Los textos, imágenes, sonidos, animaciones, software y el resto de
          contenidos incluidos en este website son propiedad exclusiva de
          GOKOSTUDIO SL o sus licenciantes. Cualquier acto de transmisión,
          distribución, cesión, reproducción, almacenamiento o comunicación
          pública total o parcial, debe contar con el consentimiento expreso de
          GOKOSTUDIO SL.
        </p>
        <p>
          Asimismo, para acceder a algunos de los servicios que GOKOSTUDIO SL
          ofrece a través del website deberá proporcionar algunos datos de
          carácter personal. En cumplimiento de lo establecido en el Reglamento
          (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de
          2016, relativo a la protección de las personas físicas en lo que
          respecta al tratamiento de datos personales y a la libre circulación
          de estos datos le informamos que, mediante la cumplimentación de los
          presentes formularios, sus datos personales quedarán incorporados y
          serán tratados en los ficheros de GOKOSTUDIO SL con el fin de poderle
          prestar y ofrecer nuestros servicios así como para informarle de las
          mejoras del sitio Web. Asimismo, le informamos de la posibilidad de
          que ejerza los derechos de acceso, rectificación, cancelación y
          oposición de sus datos de carácter personal, manera gratuita mediante
          email a info@gokostudio.com o en la dirección Cl Salvador Soler I
          Forment Num.21 P.1 Pta.1 08870 - (Sitges) - Barcelona- España
        </p>
      </div>
      <Footer />
    </LegalWrapper>
  );
};
export default Privacy;
