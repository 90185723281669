import { PortableText } from "@portabletext/react";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { client, urlFor } from "./client";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Modal from "./components/Modal/Modal";
import Navbar from "./components/Navbar/Navbar";
import Protagonistas from "./components/Protagonistas/Protagonistas";
import ReservarCita from "./components/ReservarCita/ReservarCita";
import Section from "./components/Section/Section";
import Video from "./components/Video/Video";
import { ModalProvider } from "./context/ModalContext";
import { SnackbarUtilitiesConfigurator } from "./utilities/snackbar.utility";

function App() {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const query = '*[_type == "bloques"] ';
    client.fetch(query).then((data) => {
      setSections(data[0].listaBloques);
      axios.post(
        `https://graph.facebook.com/v.3.3/686684489805473/events?access_token=EAAxD7MKAAQsBALsIwSeM0290gyVEhWLqPhE1bLLQ8zdcXJT5deqJHlRuUt3mxxLsqDjCJ2MIoDixCCVyvoBN8E8mR5duZA8HFi6BpZCb9W75uvn30PYst3qRtDUrt9O414ZC2ezyYfvXL9GQ79Y3jsYaWGTzUocsxVQB9d4vytTKumgym0E`
      );
    });
  }, []);

  return (
    <SnackbarProvider>
      <SnackbarUtilitiesConfigurator />
      <div style={{ position: "relative" }}>
        <ModalProvider>
          <ReservarCita />
          <Modal />
          <Navbar />
          <Hero />
          <div className="container section w-56">
            <p className="p-lead mb-10 p-center">
              Porque la principal función de la “la arquitectura de andar por
              casa” es mucho más que dar cobijo y protección climática, es sobre
              todo dar sustento a algo más profundo e invisible: la posibilidad
              de lo cotidiano.
            </p>
          </div>

          <div className="container section flex-column">
            {sections.map((item, index) => (
              <Section
                key={index}
                title={item.titulo}
                right={!!(index % 2 === 0)}
                img={urlFor(item.image)}
                index={index}
              >
                <PortableText value={item?.texto} />
              </Section>
            ))}
          </div>

          <Protagonistas />
          {/*   <Contact /> */}
          <Video />
          <Footer />

          {/*   <AccordionFormProvider>
        <AccordionForm />
      </AccordionFormProvider> */}
        </ModalProvider>
      </div>
    </SnackbarProvider>
  );
}

export default App;
