import styled from "styled-components";

const HeroWrapper = styled.section`
  position: relative;
  height: 100vh;
  min-height: 600px;
  max-height: 1000px;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
  }
  .hero-content {
    position: absolute;
    top: 30%;
    left: 19%;
    width: 570px;
  }
  .hero__body {
    width: 90%;
  }
  @media only screen and (max-width: 768px) {
    height: 500px;

    .hero-content {
      position: absolute;
      bottom: 10%;
      top: unset;
      left: 50%;
      width: 90%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .hero__body {
      width: 100%;
    }
  }
`;

export default HeroWrapper;
