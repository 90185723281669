import styled from "styled-components";

const SectionWrapper = styled.section`
  .flex-content {
    display: flex;
    gap: 20px;
  }
  .img-container {
    width: 40%;
    order: 2;
  }
  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .content-container {
    width: 60%;
    padding: 40px;
    background-color: var(--primary-100);
    background-color: white;
    order: 1;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section-right .content-container {
    order: 2;
  }
  @media only screen and (max-width: 1000px) {
    .flex-content {
      gap: 0px;
    }
    .content-container {
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: unset;
    }
    .img-container img {
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .section-right .content-container {
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: unset;
      border-right: 1px solid var(--gray-100);
    }
    .section-right .img-container img {
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  @media only screen and (max-width: 900px) {
    border-bottom: 1px solid var(--gray-line);
    padding-bottom: 10px;
    .flex-content {
      flex-direction: column;
    }
    .img-container {
      width: 100%;
      order: 2 !important;
    }
    .img-container img {
      border-radius: 5px;
    }
    .content-container {
      order: 1 !important;
      width: 100%;
      padding: 0;
      background-color: unset;
      border: unset;
      padding-bottom: 10px;
      border-radius: 5px;
    }
    .section-right .img-container img{
      border-radius: 5px;
    }
    .section-right .content-container{
      border: unset;
    }
    .section-margin{
      margin-top: 30px;
    }
  }
`;

export default SectionWrapper;
