import { createContext, useContext, useState } from "react";
import { useRef } from "react";
const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [secondVideo, setSecondVideo] = useState(false);
  const videoRef = useRef(null);
  const contactRef = useRef(null);
  const secondVideoRef = useRef(null)

  return (
    <ModalContext.Provider value={{ openModal, setOpenModal, videoRef, contactRef, secondVideo, setSecondVideo, secondVideoRef }}>
        {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  return useContext(ModalContext);
};

export { ModalProvider, useModalContext };
