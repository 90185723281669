import { useModalContext } from "../../context/ModalContext";
import CustomButton from "../CustomButtom/CustomButton"
import ReservarCitaWrapper from "./ReservarCitaWrapper"

const ReservarCita = () => {
    const { secondVideo } = useModalContext();

    const handleClick = () => {
        window.fbq('track', 'Contact');
        window.open("https://calendly.com/gokostudio-architects/consultoria-estrategica", '_blank');
        /*   window.location.target = '_blank'
          window.location.href = 'https://calendly.com/gokostudio-architects/consultoria-estrategica'; */
    };

    return (
        <ReservarCitaWrapper>
            {secondVideo && <CustomButton onClick={handleClick} id="reservar-cita-button1">RESERVA UNA CONSULTORÍA GRATUITA</CustomButton>}
        </ReservarCitaWrapper>

    )
}
export default ReservarCita