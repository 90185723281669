import styled from "styled-components";

const ProtagonistasWrapper = styled.section`
  .card-container{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .card-container img{
    width: 100%;
    aspect-ratio: 1/1.4;
    object-fit: cover;
    margin-bottom: 16px;
    border-radius: 5px;
  }
  .subtitle{
    margin-bottom: 10px;
    min-height: 80px;
  }
`;

export default ProtagonistasWrapper;
