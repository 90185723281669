import { NavLink } from "react-router-dom";
import FooterWrapper from "./FooterWrapper";

const Footer = () => {
  return (
    <div className="section">
      <FooterWrapper>
        <div className="container footer-section">
          <div className="footer-container">
            <NavLink to="/privacy-policies">Política de privacidad</NavLink>
            <NavLink to="/cookies">Política de cookies</NavLink>
          </div>
        </div>
      </FooterWrapper>
    </div>
  );
};
export default Footer;
