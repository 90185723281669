import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../context/ModalContext";
import CustomButton from "../CustomButtom/CustomButton";
import VideoWrapper from "./VideoWrapper";

const Video = () => {
  const { secondVideo, secondVideoRef } = useModalContext();
  const [showButton, setSHowButton] = useState();

  const handleVideoEnded = (e) => {
    if (e.target.currentTime >= 261.828935) {
      setSHowButton(true);
    } else {
      setSHowButton(false);
    }
  };

  const handleClick = () => {
    window.fbq("track", "Contact");
    window.open(
      "https://calendly.com/gokostudio-architects/consultoria-estrategica",
      "_blank"
    );
  };

  return (
    <>
      {/* {secondVideo && ( */}
      <VideoWrapper>
        <div className="container section">
          <video controls onTimeUpdate={handleVideoEnded} ref={secondVideoRef}>
            <source
              src="https://res.cloudinary.com/djnoxilr2/video/upload/v1674143839/Gokostudio-landing/VIDEO_2_subcast_sin_-_Compressed_with_FlexClip_uyiccz.mp4"
              type="video/mp4"
            />
            <source src="movie.ogg" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
          {showButton && (
            <div className="btn video-btn">
              <CustomButton onClick={handleClick} id="reservar-cita-button1">
                RESERVA UNA CONSULTORÍA GRATUITA
              </CustomButton>
            </div>
          )}
        </div>
      </VideoWrapper>
      {/* )} */}
    </>
  );
};
export default Video;
