import { PortableText } from "@portabletext/react";
import { useEffect, useState } from "react";
import { client, urlFor } from "../../client";
import ProtagonistasWrapper from "./ProtagonistasWrapper";

const Protagonistas = () => {
  const [protagonistas, setProtagonistas] = useState([]);

  useEffect(() => {
    const query = '*[_type == "protagonistas"] ';
    client.fetch(query).then((data) => {
      setProtagonistas(data[0].listaProtagonistas);
    });
  }, []);

  return (
    <ProtagonistasWrapper>
      <div className="container section">
        <h2 className="p-center">Nuestros protagonistas. Sus historias.</h2>
        <div className="flex">
          {protagonistas.map((item, index) => (
            <div className="card-container" key={index}>
             {item?.image && <img src={urlFor(item.image)} />}
              <p className="subtitle">{item?.subtitulo}</p>

              <div className="p-quote p-justify">
                <PortableText value={item?.comentario} />
              </div>
              <p className="p-strong flex-end">{item?.titulo}</p>
            </div>
          ))}
        </div>
      </div>
    </ProtagonistasWrapper>
  );
};
export default Protagonistas;
