import { useModalContext } from "../../context/ModalContext";
import ModalWrapper from "./ModalWrapper";
import { BsXLg } from "react-icons/bs";
const Modal = () => {
  const { openModal, setOpenModal, videoRef } = useModalContext();
  if (openModal) document.body.style.overflowY = "hidden";
  else document.body.style.overflowY = "scroll";

  const handleClick = () => {
    setOpenModal(false);
    videoRef.current.play();
  };
  return (
    <>
      {openModal && (
        <ModalWrapper>
          <BsXLg className="close-icon" onClick={handleClick} />
          <div
            className="video-container"
          >
            <video width='100%' height='100%' controls autoPlay>
              <source src="https://res.cloudinary.com/djnoxilr2/video/upload/v1671539294/Gokostudio-landing/video1_oqknpi.mp4" type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
export default Modal;
