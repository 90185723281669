import { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { client } from "../../client";
import { useModalContext } from "../../context/ModalContext";
import ContactWrapper from "../Contact/ContactWrapper";
import CustomButton from "../CustomButtom/CustomButton";
import HeroWrapper from "./HeroWrapper";

const Hero = () => {
  const { setSecondVideo, videoRef, secondVideoRef } = useModalContext();
  const [data, setData] = useState();
  /* 
  const handleClick = () => {
    setOpenModal(true);
    videoRef.current?.pause();
  };

   */

  const handleClick = () => {
    secondVideoRef.current?.scrollIntoView({ behavior: "smooth" });
    secondVideoRef.current?.play();
    setSecondVideo(true);
  };

  useEffect(() => {
    const query = '*[_type == "portada"] ';
    client.fetch(query).then((data) => {
      setData(data[1]);
    });
  }, []);

  return (
    <HeroWrapper>
      {data?.image && data.image.duration == null && (
        <img src={data.image.url} />
      )}
      {data?.image && data.image.duration != null && (
        <video ref={videoRef} autoPlay muted loop>
          <source
            src="https://res.cloudinary.com/djnoxilr2/video/upload/v1671538677/Gokostudio-landing/video1_vaxq5x.mp4"
            type="video/mp4"
          />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="hero-bg" />
      <div className="hero-content">
        <h1 className="mb-30">{data?.titulo}</h1>

        <div className="hero__body">
          <ContactWrapper>
            <CustomButton
              variant="text"
              id="ver-video-button"
              style={{ color: "white", textAlign: "left" }}
              endIcon={<BsArrowDown />}
              onClick={handleClick}
            >
              Descubre en este vídeo las claves para conseguirlo
            </CustomButton>
          </ContactWrapper>
        </div>
      </div>
    </HeroWrapper>
  );
};
export default Hero;
