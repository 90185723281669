import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Cookies from "./pages/Cookies";
import Privacy from "./pages/Privacy";
import CookiesDialog from "./components/CookiesDialog/CookiesDialog";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesDialog />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy-policies" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
