import "./custom-button.scss";

const CustomButton = ({
  variant = "contained",
  rounded,
  startIcon,
  endIcon,
  children,
  position,
  onClick,
  style,
  id,
}) => {
  let styles = "";
  if (variant == "contained") styles += "btn-contained ";
  if (variant == "outlined") styles += "btn-outlined ";
  if (variant == "text") styles += "btn-text ";
  if (variant == "icon") styles += "btn-icon ";

  if (rounded === "squared") styles += "btn-squared ";
  if (rounded === "rounded") styles += "btn-rounded ";

  if (position === "first") styles += "first-group ";
  if (position === "middle") styles += "middle-group ";
  if (position === "middle-next") styles += "middle-next-group ";
  if (position === "last") styles += "last-group ";

  return (
    <button id={id} className={styles} onClick={onClick} style={style}>
      {startIcon}
      <span className="text">{children}</span>
      {endIcon}
    </button>
  );
};
export default CustomButton;
