import styled from "styled-components";

const CookiesDialogWrapper = styled.section`
  .cookie-dialog {
    position: fixed;
    bottom: 30px;
    left: 30px;
    /* right: 30px; */
    z-index: 10;
    width: 750px;
    background-color: white;
    padding: 30px;
    border: 1px solid var(--gray-100);
    border-radius: 4px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .cookie-dialog button {
    margin-top: 30px;
  }
  .icon {
    font-size: 90px;
    margin-bottom: 20px;
  }
  .cookie-dialog a {
    color: var(--button-main);
    cursor: pointer;
  }
  .cookie-dialog a:hover {
    color: var(--button-dark);
  }
  @media only screen and (max-width: 768px) {
    .cookie-dialog {
      width: unset;
      right: 30px;
    }
  }
`;

export default CookiesDialogWrapper;
