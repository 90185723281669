import styled from "styled-components";

const ButtonWrapper = styled.div`
  .btn {
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;

    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.02857em;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition-slow);
    border-radius: 5px;
  }
  .btn .text {
    padding: 0rem 0.5rem;
  }
  .btn-filled {
    color: white;
    background-color: var(--button-main);
    border: unset;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .btn-rounded {
    border-radius: 60px;
  }

  .btn-text {
    color: var(--primary);
    background-color: unset;
    border: unset;
  }
  .btn svg {
    stroke-width: 0.6;
  }
  .btn-filled:hover {
    background-color: var(--button-dark);
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.1);
  }
  .btn:active {
    background-color: var(--primary-300);
  }
  .disabled{
    background-color: var(--primary-250);
    cursor: default;
  }
  .disabled:hover, .disabled:active{
    background-color: var(--primary-250);
  }
  @media only screen and (max-width: 768px) {
    .btn {
      margin: 0 auto;
    }
  }
`;

export default ButtonWrapper;
