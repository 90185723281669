import styled from "styled-components";

const ContactWrapper = styled.section`
  .content-container {
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button-group {
    margin-top: 12px;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .text-input {
    padding: 17px;
    width: 100%;
    color: var(--gray--light);
    font-size: 18px;
    border: 1px solid var(--gray-line);
    border-right: unset;
    border-radius: 5px 0px 0 5px;
  }
  .text-input:focus {
    border: 1px solid var(--button-main);
    outline: none;
  }
  .icon-btn {
    font-size: 17px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .input-error {
    border: 1px solid var(--error) !important;
  }
  @media only screen and (max-width: 768px) {
    .content-container {
      padding: 0;
    }
  }
`;

export default ContactWrapper;
