import { BsArrowRight } from "react-icons/bs";
import { useModalContext } from "../../context/ModalContext";
import CustomButton from "../CustomButtom/CustomButton";
import SectionWrapper from "./SectionWrapper";

const Section = ({ title, right, img, index, children }) => {
  const { secondVideoRef, setSecondVideo } = useModalContext();

  const handleClick = () => {
    secondVideoRef.current?.scrollIntoView({ behavior: "smooth" });
    secondVideoRef.current?.play();
    setSecondVideo(true);
  };
  const setSectionClass = (index) => {
    let style = "flex-content ";
    if (right) style += "section-right ";
    if (index >= 1) style += "section-margin";
    return style;
  };

  return (
    <SectionWrapper>
      <div className={setSectionClass(index)}>
        <div className="img-container">
          <img src={img} />
        </div>

        <div className="content-container">
          <h2>{title}</h2>
          {/*  <div className="mb-30" dangerouslySetInnerHTML={{ __html: children }} /> */}
          {children}
          {/* <p className="mb-30">{children}</p> */}
          <CustomButton
            variant="text"
            rounded="squared"
            endIcon={<BsArrowRight />}
            onClick={handleClick}
          >
            Descúbrelo en este vídeo
          </CustomButton>
        </div>
      </div>
    </SectionWrapper>
  );
};
export default Section;
