import styled from "styled-components";

const NavbarWrapper = styled.header`
  position: absolute;
  width: 100vw;
  z-index: var(--zIndex-7--navbar);
  padding: 17px 0;
`;

export default NavbarWrapper;
