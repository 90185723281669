import styled from "styled-components";

const LegalWrapper = styled.section`
  h3 {
    margin-top: 35px;
    margin-bottom: 15px;
  }
  p{
    margin-bottom: 20px;
  }
  ul {
    margin-left: 50px;
    margin-top: 20px;
    margin-bottom: 35px;
  }
  li {
    color: var(--gray-700);
    font-weight: 300;
    margin-bottom: 20px;
  }
`;

export default LegalWrapper;
