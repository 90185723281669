import { Link } from "react-router-dom";
import CookiesDialogWrapper from "./CookiesDialogWrapper";
import CustomButton from "../CustomButtom/CustomButton";

import { useState } from "react";
import Cookies from "js-cookie";

const CookiesDialog = () => {
  const [hasCookie, setHasCookie] = useState(
    Boolean(Cookies.get("gokostudio-cookies"))
  );

  const handleClick = () => {
    Cookies.set("gokostudio-cookies", "true", { expires: 7 });
    setHasCookie(true);
  };
  return (
    <CookiesDialogWrapper>
      {!hasCookie && (
        <div className="cookie-dialog">
          <h2>Políticas de cookies</h2>
          <p>
            La web de Goko Studio hace uso de cookies propias y de terceros con
            el fin de mejorar nuestros servicios. Si continúas navegando,
            consideramos que aceptas su uso. Si deseas obtener más información,
            puedes acceder a la{" "}
            <Link to="/cookies" className="">
              Política de Cookies
            </Link>
          </p>
          <CustomButton onClick={handleClick}>Aceptar</CustomButton>
        </div>
      )}
    </CookiesDialogWrapper>
  );
};
export default CookiesDialog;
