import styled from "styled-components";

const FooterWrapper = styled.section`
  border-top: 1px solid var(--gray-200);
  background-color: white;

  .footer-section{
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .footer-container{  
    display: flex;
    gap: 30px;
    justify-content: end;
  }
  .footer-container a{
    color: var(--button-main);
    cursor: pointer;
  }
  .footer-container a:hover{
    color: var(--button-dark);
  }
`;

export default FooterWrapper;
