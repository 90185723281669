import styled from "styled-components";

const VideoWrapper = styled.div`
    position: relative;
    video{
        width: 100%;
    }
    .video-btn{
        position: absolute;
        top: 70%;
        right: 25%;
        z-index: 100;
    }
  
`;

export default VideoWrapper;
